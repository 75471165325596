@import '../../styles/customMediaQueries.css';

.subscriptionDetailsContainer {
  display: flex;
  justify-content: space-between;
}

.subscriptionDetailsContainer > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subsciptionStar {
  width: 32px;
  margin-right: 8px;
  @media (--viewportMedium) {
    margin-right: 20px;
  }
}

.activeSubscription {
  height: 25px;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  background-color: #2ecc71;
  margin: auto 20px;
  border-radius: 4px;
  padding: 5px;
}

.expiredSubscription {
  height: 25px;
  width: 67px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  background-color: #808080;
  margin: auto 20px;
  border-radius: 4px;
  padding: 5px;
}

.subscriptionType {
  font-weight: 600;
  font-size: 13px;
  color: #4a4a4a;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 11px;
  }
}

.renewalStatus {
  font-weight: 300;
  font-size: 11px;
  color: #4a4a4a;
  margin: 0;
  @media (--viewportMedium) {
    font-size: 11px;
  }
}

.cancelRenewal {
  font-weight: 600;
  font-size: 14px;
  color: #d10000;
  margin: auto 20px;
  cursor: pointer;
}

.enableRenewal {
  font-weight: 600;
  font-size: 14px;
  color: #214b68;
  margin: auto 20px;
  cursor: pointer;
}
